export class ValidationError extends Error {
  constructor(public message: string) {
    super(message);
    Object.setPrototypeOf(this, ValidationError.prototype);
  }
}

export class NoRentalHistoryError extends ValidationError {
  constructor() {
    super('');
    Object.setPrototypeOf(this, NoRentalHistoryError.prototype);
  }
}
